<template>
  <div>
    <div class="w-100 d-flex justify-content-start">
      <b-button variant="primary" class="mb-1" @click="handleAdd">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table :fields="fields" :items="hotels" responsive :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal hide-footer v-model="formModalActive" size="lg" no-close-on-backdrop>
      <HotelAction :hotelId="hotelId" :countries="countries" @closeTourHotels="closeModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import {
    createCountryById
  } from '@/api/countries/countries'
  import {
    deleteHotelSale,
    getClientHotels
  } from '@/api/hotels'
  import HotelAction from '@/views/directories/clients/components/hotels/HotelForm.vue'

  export default {
    name: 'Hotels',
    components: {
      HotelAction
    },
    data() {
      return {
        id: null,
        hotelId: null,
        hotels: [],
        countries: [],
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'hotel.name',
            label: this.$t('message.Name')
          },
          {
            key: 'start_date',
            label: this.$t('message.Start_date')
          },
          {
            key: 'end_date',
            label: this.$t('message.End_date')
          },
          {
            key: 'hotel.country.name_ru',
            label: this.$t('message.Countries')
          },
          {
            key: 'author.name',
            label: this.$t('message.Author'),
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        formModalActive: false,
        busy: true,
      }
    },
    created() {
      this.fetchHotels()
    },
    methods: {
      fetchHotels() {
        getClientHotels({
          client_id: this.$route.params.id
        }).then(response => {
          this.hotels = response.data.data
          this.busy = false;
        })
      },
      handleAdd() {
        this.id = null;
        this.formModalActive = true
        createCountryById().then(res => {
          this.countries = res.data.data.data
        })
      },
      handleEdit(id) {
        this.hotelId = id;
        this.formModalActive = true
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchHotels()
        this.hotelId = null;
        this.id = null;
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteHotelSale({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchHotels()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
    }
  }
</script>