<template>
  <div>
    <b-button
      variant="outline-primary"
      size="sm"
      @click="generateWordDocument(dataUser)"
    >
      <b-icon icon="file-earmark-medical"></b-icon>
    </b-button>
  </div>
</template>

<script>
  import {
    Document,
    Packer,
    Paragraph,
    Table,
    TableRow,
    TableCell,
    AlignmentType,
    // BorderStyle,
    WidthType,
    VerticalAlign,
    // LineRuleType,
    TextRun,
  } from "docx";
  import { saveAs } from "file-saver";
  import moment from 'moment'
  export default {
    name: "Word",
    props: {
      dataUser: {
        required: true
      }
    },
    data() {
      return {
        newDate: moment(new Date()).format('DD-MM-YYYY'),
      }
    },
    methods: {
      generateWordDocument(user) {
        console.log(user);
        console.log(this.newDate);
        // Create a new instance of Document for the docx module
        let doc = new Document({
          sections: [
            {
              children: [
                new Paragraph({
                  text: "ДОГОВОР НА ОКАЗАНИЕ ТУРИСТИЧЕСКИХ УСЛУГ ",
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      // spacing: {
                      //   after: 140
                      // },
                      text: `№ ${user.client.id}`,
                    }),
                  ],
                }),
                new Paragraph({
                  text: "г. Ташкент,",
                  spacing: {
                    after: 140,
                    before: 140,
                  },
                  children: [
                    new TextRun({
                      text: `  ${this.newDate}`,
                    }),
                  ],
                }),
                new Paragraph({
                  text: `ООО «ALFA-TOUR GROUP», именуемое в дальнейшем  «ИСПОЛНИТЕЛЬ», в лице директора Тухтасинов Д.Р, действующего на основании `,
                  spacing: {
                    before: 140,
                    after: 140,
                  },
                }),
                new Paragraph({
                  text: `Устава, с одной стороны и гр-н Республики Узбекистан    `,
                  spacing: {
                    after: 140,
                    before: 140,
                  },
                  children: [
                    new TextRun({
                      text: `${user.client.full_name}`,
                    }),
                  ],
                }),
                new Paragraph({
                  text: `Паспорт: ${user.client.passport_serial}  ${user.client.passport_number}  `,
                  spacing: {
                    after: 140,
                    before: 140,
                  },
                  children: [
                    new TextRun({
                      text: "именуемый(ая) в дальнейшем «ЗАКАЗЧИК», c другой стороны, заключили настоящий",
                    }),
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    after: 140,
                    before: 140,
                  },
                  children: [
                    new TextRun({
                      text: "1. ПРЕДМЕТ ДОГОВОРА",
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140,
                  },
                  children: [
                    new TextRun({
                      text: "1.1 ЗАКАЗЧИК поручает и оплачивает, а ИСПОЛНИТЕЛЬ принимает на себя обязательства по оказанию следующих видов туристических услуг:",
                      bold: true,
                    }),
                  ],
                }),
                new Table({
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  rows: [
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "#",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "Наименование товара",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "Ед.из.",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "Кол-во взр, Кол-во дет",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "Цена",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Table({
                              borders: {
                                top: {
                                  size: 0,
                                  color: "000000",
                                },
                                bottom: {
                                  size: 0,
                                  color: "000000",
                                },
                                left: {
                                  size: 0,
                                  color: "000000",
                                },
                                right: {
                                  size: 0,
                                  color: "000000",
                                },
                              },
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              rows: [
                                new TableRow({
                                  height: {
                                    value: 350,
                                    rule: 350,
                                  },
                                  children: [
                                    new TableCell({
                                      width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: "НДС",
                                          alignment: AlignmentType.CENTER,
                                        }),
                                      ],
                                    }),
                                  ],
                                }),
                                new TableRow({
                                  height: {
                                    value: 350,
                                    rule: 350,
                                  },
                                  children: [
                                    new TableCell({
                                      width: {
                                        size: 50,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: "Ставка",
                                          alignment: AlignmentType.CENTER,
                                        }),
                                      ],
                                    }),
                                    new TableCell({
                                      width: {
                                        size: 50,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: "Сумма",
                                          alignment: AlignmentType.CENTER,
                                        }),
                                      ],
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "Сумма с учетом НДС",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    // items
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "1",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                              },
                              text: "Услуги по предоставлению туров на необходимое количество человек",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          rowSpan: 7,
                          children: [
                            new Paragraph({
                              text: "сум",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          rowSpan: 7,
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "100000",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          rowSpan: 7,
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Table({
                              borders: {
                                top: {
                                  size: 0,
                                  color: "000000",
                                },
                                bottom: {
                                  size: 0,
                                  color: "000000",
                                },
                                left: {
                                  size: 0,
                                  color: "000000",
                                },
                                right: {
                                  size: 0,
                                  color: "000000",
                                },
                              },
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              rows: [
                                new TableRow({
                                  height: {
                                    value: 350,
                                    rule: 350,
                                  },
                                  children: [
                                    new TableCell({
                                      width: {
                                        size: 50,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: "Без ",
                                          alignment: AlignmentType.CENTER,
                                        }),
                                      ],
                                    }),
                                    new TableCell({
                                      width: {
                                        size: 50,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: "НДС",
                                          alignment: AlignmentType.CENTER,
                                        }),
                                      ],
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          rowSpan: 7,
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "100000",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "2",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                              },
                              text: "Услуги по предоставлению мест проживания (включая бронирование)",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "3",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                              },
                              text: "Услуги по предоставлению питания",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "4",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                              },
                              text: "Информационные услуги",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "5",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                              },
                              text: "Транспортные услуги (включая бронирование, продажу и доставку билетов)",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "6",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                              },
                              text: "Услуги по предоставлению (включая бронирование, продажу и доставку) билетов на все виды транспорта и билетов на посещение мероприятий и объектов развлечения",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableRow({
                      tableHeader: true,
                      height: {
                        value: 700,
                        rule: 700,
                      },
                      children: [
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 2,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: "7",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          width: {
                            size: 23,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              spacing: {
                                before: 100,
                                after: 100,
                                // lineRule: LineRuleType.AT_LEAST,
                                // line: 300
                              },
                              text: "Услуги по оказанию визовой поддержки ",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 14,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              text: "",
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  alignment: AlignmentType.END,
                  text: `ИТОГО: ________`
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140,
                  },
                  children: [
                    new TextRun({
                      text: "1.2 Общая сумма договора составляет (размер вознаграждения) Без НДС:",
                      bold: true,
                      break: 1
                    }),
                    new TextRun({
                      text: 'сто тысяч сум',
                      break: 2
                    })
                  ],
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '2. Обязательства сторон',
                      // bold: true
                    })
                  ]
                }),

                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '2.1 ИСПОЛНИТЕЛЬ обязуется:',
                      bold: true,
                      break: 2,
                    }),
                    new TextRun({
                      text: '2.1.1. Оказать услуги, указанные в пункте 1.1, надлежащим образом и в полном  объеме;',
                      break: 1,
                    }),
                    new TextRun({
                      text: '2.1.2. Информировать  ЗАКАЗЧИКА о ходе выполнения работ;',
                      break: 1,
                    }),
                    new TextRun({
                      text: '2.1.3. Обеспечить ЗАКАЗЧИКА полной информацией о предоставляемых туристических услугах;',
                      break: 1,
                    }),
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '2.2. ЗАКАЗЧИК обязуется:',
                      bold: true,
                      break: 2,
                    }),
                    new TextRun({
                      text: '2.2.1. Своевременно предоставить ИСПОЛНИТЕЛЮ необходимые документы и информацию для организации туристической поездки;',
                      break: 1,
                    }),
                    new TextRun({
                      text: '2.2.2. Произвести оплату за предоставленные услуги;',
                      break: 1,
                    }),
                    new TextRun({
                      text: '2.2.3. Оплатить либо возместить все расходы ИСПОЛНИТЕЛЯ по приобретению билетов на транспорт, гостиничных номеров, визового сбора, билетов на посещение мероприятий и т.п.;',
                      break: 1,
                    }),
                    new TextRun({
                      text: '2.2.4. Информировать ИСПОЛНИТЕЛЯ обо всех событиях или обстоятельствах, которые могут послужить основанием для срыва поездки и аннуляции заказа немедленно после их возникновения или сразу после того, как ему стало об этом известно (например, отказ посольства в предоставлении визы, невозможность приобретения авиабилетов и пр.;',
                      break: 1,
                    }),
                    new TextRun({
                      text: '2.2.5. Строго соблюдать сроки своего возвращения из страны пребывания без нарушения визового режима данного государства',
                      break: 1,
                    }),
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `2.3. ЗАКАЗЧИК ознакомлен ИСПОЛНИТЕЛЕМ с условиями и информацией по поездке в страну, указанную в пункте 1.1., об ультрафиолетовом и биологическом воздействии в данной стране, а также о выполнении Постановления Президента РУз №  911 от 8 июля 1408 г. «О мерах по повышению эффективности борьбы с торговлей людьми".;`,
                      bold: true,
                    })
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '3. Порядок оплаты и расчетов',
                      bold: true,
                      break: 1,
                    }),
                    
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `3.1. Расходы по приобретению билетов на транспорт, гостиничных номеров, визового сбора, билетов на посещение мероприятий и т.п. оплачиваются ЗАКАЗЧИКОМ в размере 100 % в течение ____ дней с момента подписания настоящего Договора и предоставления ИСПОЛНИТЕЛЕМ соответствующей калькуляции, с указанием общей суммы расходов. `,
                      break: 1
                    }),
                    new TextRun({
                      text: '3.2. В случае оплаты каких-либо из указнных расходов ИСПОЛНИТЕЛЕМ самостоятельно, ЗАКАЗЧИК возмещает их в течение ____ дней с момента предоставления ИСПОЛНИТЕЛЕМ документов, подтверждающих понесенные им расходы, в результате оказания услуг.',
                      break: 1,
                    }),
                    new TextRun({
                      text: '3.3. Стоимость туристических услуг, указанных в пункте 1.1. настоящего Договора, предоставляемых ИСПОЛНИТЕЛЕМ (размер вознаграждения) оплачиваются ЗАКАЗЧИКОМ в течение ____ дней с момента подписания настоящего Договора.',
                      break: 1,
                    }),
                    new TextRun({
                      text: '3.4. Расчеты за предоставляемые туристические услуги и оплата расходов, указанных в пункте 3.1.-3.3. настоящего Договора, осуществляются ЗАКАЗЧИКОМ в узбекских сумах.',
                      break: 1,
                    }),
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '4. Ответственность сторон.',
                      bold: true,
                      break: 1,
                    }),
                  
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      break: 1,
                      text: `4.1. ЗАКАЗЧИК несет ответственность за достоверность и своевременность предоставления выездных документов участников поездок, их подлинность и полноту.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.2. ИСПОЛНИТЕЛЬ не несет ответственность за прохождение туристами таможни и пограничного поста, за подлинность выездных и въездных документов, предоставленных туристами, и не возвращает стоимость услуг в случае задержки туристов на таможне или возврата их с границы. ИСПОЛНИТЕЛЬ не оплачивает дополнительные расходы, связанные со снятием обслуживаемых граждан с поездки таможенными, пограничными и/ или/ иными уполномоченными на то службами.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.3. ИСПОЛНИТЕЛЬ оставляет за собой право заменять бронируемые услуги в пределах заявленной ЗАКАЗЧИКОМ категории.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.4. ИСПОЛНИТЕЛЬ не несет ответственность, если поездка не может быть осуществлена в силу форс-мажорных обстоятельств, а также условий транспортировки туриста и его багажа в Республике Узбекистан или странах пребывания. `
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.5. В случае если поездка не может быть осуществлена или была сорвана в результате наступления форс-мажорных обстоятельств, ИСПОЛНИТЕЛЬ не несет ответственность за возврат денежных средств, уплаченных ЗАКАЗЧИКОМ за приобретение билетов на транспорт, гостиничных номеров, визового сбора, билетов на посещение мероприятий и т.п.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.6. ИСПОЛНИТЕЛЬ не несет ответственность за сохранность багажа участников поездки и расходы, связанные с оплатой сверхнормативного багажа.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.7. Если турист в течение поездки совершает действия, которые несут за собой наложения штрафа, он обязан оплатить этот штраф.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.8. Ответственность за ущерб, принесенный туристом третьему лицу в процессе поездки, несет турист.`
                    }),
                    new TextRun({
                      break: 1,
                      text: `4.9. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору, стороны несут ответственность в соответствии с действующим законодательством Республики Узбекистан.`
                    }),
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `5. Условия аннуляции`,
                      bold: true
                    })
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `5.1. ЗАКАЗЧИК может отказаться от услуг в любое время, уведомив ИСПОЛНИТЕЛЯ о своем отказе в письменной рукописной форме.`,
                      break: 1
                    }),
                    new TextRun({
                      text: `5.2. ЗАКАЗЧИК, при аннуляции услуг, будет иметь право возврата уплаченной суммы в порядке и на условиях, указанных в п. 4.3 настоящего Договора.`,
                      break: 1
                    }),
                    new TextRun({
                      text: `5.3. В случае аннуляции услуг или любых изменений подтвержденных туристских услуг до начала поездки,  ЗАКАЗЧИК уплачивает ИСПОЛНИТЕЛЮ следующие штрафные санкции:`,
                      break: 1
                    }),
                    new TextRun({
                      text: `Период отказа Размеры штрафа`,
                      break: 1
                    }),
                    new TextRun({
                      text: `Более 30 дней 10  %  от стоимости туристских услуг`,
                      break: 1
                    }),
                    new TextRun({
                      text: `От 30 до 16 дней  25  %  от стоимости туристских услуг`,
                      break: 1
                    }),
                    new TextRun({
                      text: `От 15 до 6 дней 50  %  от стоимости туристских услуг`,
                      break: 1
                    }),
                    new TextRun({
                      text: `5 дней и менее 100 % от стоимости туристских услуг`,
                      break: 1
                    }),
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '6. Порядок разрешения споров',
                      bold: true
                    })
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `6.1. Все споры по настоящему договору, не улаженные в рабочем порядке, рассматриваются в суде в порядке, установленном действующим законодательством Республики Узбекистан.`
                    })
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '7. Сроки действия договора',
                      bold: true
                    })
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `7.1. Настоящий Договор вступает в силу с момента его подписания и действует до 31 декабря 2021 года.`,
                      break: 1
                    }),
                    new TextRun({
                      text: `7.2. Настоящий Договор составлен в 2-х экземплярах, по одному для каждой из сторон, каждый из которых имеет одинаковую юридическую силу. `,
                      break: 1
                    })
                  ]
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: '8. Адреса и реквизиты сторон.',
                      bold: true
                    })
                  ]
                }),

                new Table({
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE
                  },
                  borders: {
                    top: {
                      size: 0
                    },
                    left: {
                      size: 0                        
                    },
                    right: {
                      size: 0
                    },
                    bottom: {
                      size: 0
                    }
                  },
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Table({
                              width: {
                                size: 90,
                                type: WidthType.PERCENTAGE,
                              },
                              // alignment: AlignmentType.CENTER,
                              rows: [
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          alignment: AlignmentType.CENTER,
                                          children: [
                                            new TextRun({
                                              text: `«ИСПОЛНИТЕЛЬ»`,
                                              bold: true,
                                              break: 1
                                            }),
                                            new TextRun({
                                              text: 'ООО «ALFA-TOUR GROUP»',
                                              bold: true,
                                              break: 1
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  Адрес: `,
                                              bold: true
                                            }),
                                            new TextRun({
                                              text: '100000 г. Ташкент, ул, Заркайнар 2–Б'
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  р/с `,
                                              bold: true
                                            }),
                                            new TextRun({
                                              text: 'UZS: 2020 8000 9002 5752 9001'
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  р/с `,
                                              bold: true,
                                            }),
                                            new TextRun({
                                              text: 'USD: 2020 8840 1002 5752 9001'
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: `  В АКБ «InFinBank» МФО 01041`,
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  Тел: `,
                                              bold: true
                                            }),
                                            new TextRun({
                                              text: `398-99-99, 244-72-22, 244-90-00`,
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: '   Директор',
                                          children: [
                                            new TextRun({
                                              text: `   Tukhtasinov D.R._________________`,
                                              break: 1
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                              ]
                            }),
                          ]
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              size: 0
                            },
                            left: {
                              size: 0                        
                            },
                            right: {
                              size: 0
                            },
                            bottom: {
                              size: 0
                            }
                          },
                          children: [
                            new Table({
                              // spacing: {
                              //   after: 140,
                              //   before: 140
                              // },
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              borders: {
                            // top: {
                            //   size: 0
                            // },
                            left: {
                              size: 0                        
                            },
                            // right: {
                            //   size: 0
                            // },
                            // bottom: {
                            //   size: 0
                            // }
                          },
                              // alignment: AlignmentType.CENTER,
                              rows: [
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          alignment: AlignmentType.CENTER,
                                          children: [
                                            new TextRun({
                                              text: `«ЗАКАЗЧИК»`,
                                              bold: true,
                                              break: 1
                                            }),
                                            new TextRun({
                                              text: `${user.client.full_name}`,
                                              bold: true,
                                              break: 1
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  Адрес: `,
                                              bold: true
                                            }),
                                            new TextRun({
                                              text: '_______________________'
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  Паспорт:  `,
                                              bold: true
                                            }),
                                            new TextRun({
                                              text: `${user.client.passport_serial}  ${user.client.passport_number}`
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  Выдан: `,
                                              bold: true,
                                            }),
                                            new TextRun({
                                              text: `${user.client.given_date}`
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 400,
                                    rule: 400,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({
                                              text: `  MIA: `,
                                              bold: true
                                            }),
                                            new TextRun({
                                              text: `_______________________`,
                                            })
                                          ]
                                        })
                                      ]
                                    })
                                  ]
                                }),
                                new TableRow({
                                  height: {
                                    value: 850,
                                    rule: 850,
                                  },
                                  children: [
                                    new TableCell({
                                      verticalAlign: VerticalAlign.CENTER,
                                      children: [
                                        new Paragraph({
                                          text: `    ${user.client.full_name}`,
                                        })
                                      ]
                                    })
                                  ]
                                }),
                              ]
                            }),
                          ]
                        }),
                      ]
                    })
                  ]
                }),

                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  text: '  С информацией, указанной в пункте 2.3. настоящего Договора ознакомлен:',
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  spacing: {
                    after: 140,
                    before: 140
                  },
                  children: [
                    new TextRun({
                      text: `${user.client.full_name}`,
                      bold: 1,
                      break: 1
                    }),
                    new TextRun({
                      text: '                 Подпись _________________',
                    })
                  ]
                })

              ],
            },
          ],
        });
        // Call saveDocumentToFile with the document instance and a filename
        this.saveDocumentToFile(doc, "document.docx");
      },
      saveDocumentToFile(doc, fileName) {
        // Create a mime type that will associate the new file with Microsoft Word
        const mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        // Create a Blob object from Packer containing the Document instance and the mimeType
        Packer.toBlob(doc).then((blob) => {
          const docblob = blob.slice(0, blob.size, mimeType);
          // Save the file using saveAs from the file-saver package
          saveAs(docblob, fileName);
        });
      },
    },
  };
</script>

<style scoped>
#button {
  border: none;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  transition: 500ms;
  cursor: pointer;
}

#button:hover {
  background-color: darkgreen;
}
</style>