<template>
  <div class="w-100">
    <div class="w-100 d-flex justify-content-start">
      <b-button variant="primary" class="mb-1" @click="handleAdd">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table :fields="fields" :items="visas" responsive :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
        </template>
        <template #cell(author)="data">
          <div v-if="data.item.author_id === 0">
            <span>Self made</span>
          </div>
          <div v-else>
            <span>{{ data.item.author.name }}</span>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button
                variant="outline-success"
                size="sm"
                @click="handleShow(data.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal hide-footer v-model="visaFormActive" no-close-on-backdrop>
      <FormVisa :id="id" @closeVisaModal="closeVisaModal" />
    </b-modal>
  </div>
</template>

<script>
  import FormVisa from '@/views/directories/clients/components/visa/Form'
  import Swal from 'sweetalert2'
  import {
    deleteVisa,
    getVisas
  } from '@/api/visa'

  export default {
    name: 'Visa',
    components: {
      FormVisa
    },
    data() {
      return {
        id: null,
        visas: [],
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'country.name_ru',
            label: this.$t('message.Countries')
          },
          {
            key: 'price',
            label: this.$t('message.Actual_price')
          },
          {
            key: "fee",
            label: this.$t('message.Fee')
          },
          {
            key: "enter_date",
            label: 'Entry date'
          },
          {
            key: "release_date",
            label: 'Release date'
          },
          {
            key: 'author',
            label: 'Source',
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        tours: [],
        visaFormActive: false,
        busy: true,
      }
    },
    created() {
      this.fetchVisa()
    },
    methods: {
      fetchVisa() {
        getVisas({
          client_id: this.$route.params.id
        }).then(response => {
          this.visas = response.data.data;
          this.visas.forEach(item => item.actual_price = parseInt(item.actual_price))
          this.visas.forEach(item => item.market_price = parseInt(item.market_price));
          this.busy = false;
        })
      },
      handleAdd() {
        this.id = null;
        // this.visaFormActive = true
        this.$router.push('/visa/visa-add/' + this.$route.params.id)
      },
      handleEdit(id) {
        this.$router.push({name: 'visa-update', params: {visaId: id}})
      },
      closeVisaModal() {
        this.visaFormActive = false;
        this.fetchVisa()
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteVisa({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchVisa()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      handleShow(id) {
        this.$router.push({name: 'visa-details', params: {visaDetailsId: id}})
      }
    }
  }
</script>

<style scoped>

</style>