<template>
  <div class="w-100">
    <validation-observer ref="observer">
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('message.Title')" rules="required"
          class="w-100 mt-1">
          <label for="title">{{$t('message.Title')}}</label>
          <b-input v-model="visa.title"></b-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <validation-provider #default="validationContext" :name="$t('message.Countries')" rules="required"
        class="w-100 mt-1">
        <label class="mt-2">{{$t('message.Countries')}}</label>
        <!--        <b-form-select-->
        <!--            v-model="visa.country_id"-->
        <!--            :options="countries"-->
        <!--            text-field="name_uz"-->
        <!--            value-field="id"-->
        <!--            class="w-100"-->
        <!--        />-->
        <v-select :options="countries" label="name_ru" v-model="visaCountry" class="w-100" @search="SearchVisaCountry">
        </v-select>
        <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
      </validation-provider>
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" name="Visa Сумма брутто" rules="required|numeric"
          class="w-100 mt-1">
          <label for="actual_price">
            <span class="d-flex">
              <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
            </span>
          </label>
          <b-input v-model="visa.actual_price"></b-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" name="Visa Сумма нетто" rules="required|numeric"
          class="w-100 mt-1">
          <label for="market_price">
            <span class="d-flex">
              <box-icon name='wallet-alt' type="solid"></box-icon><span>{{$t('message.Net_amount')}}</span>
            </span>
          </label>
          <b-input v-model="visa.market_price"></b-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('message.Currency')" rules="required"
          class="w-100 mt-1">
          <label for="currency">{{ $t('message.Currency') }}</label>
          <b-form-select v-model="visa.currency_id" :options="currencies" text-field="name" value-field="id"
            class="w-100" />
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="warning" @click="$emit('closeVisaModal')" class="mr-1">{{$t('message.Cancel')}}</b-button>
      <b-button variant="primary" @click="saveSubmit" :disabled="disabled">{{$t('message.Add')}}</b-button>
    </div>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver,
    extend
  } from 'vee-validate'
  import {
    required
  } from '@validations'
  import {
    getVisa,
    storeVisa
  } from '@/api/visa'
  import {
    getCountries
  } from '@/api/countries/countries'
  import vSelect from 'vue-select';
  import * as rules from 'vee-validate/dist/rules';
  import {
    messages
  } from 'vee-validate/dist/locale/ru.json';

  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    });
  });

  export default {
    name: 'Form',
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      id: {
        default: null,
      }
    },
    data() {
      return {
        required,
        visa: {
          title: '',
          actual_price: null,
          market_price: null,
          country_id: null,
          client_id: this.$route.params.id,
          currency_id: null,
        },
        visaCountry: null,
        countries: [],
        countryTimeOut: null,
        disabled: false,
      }
    },
    computed: {
      currencies() {
        return this.$store.state.settings.currencies;
      }
    },
    methods: {
      saveSubmit() {
        const isValid = this.$refs.observer.validate();
        if (isValid) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 1000)

          this.visa.country_id = this.visaCountry.id;
          storeVisa(this.visa).then(res => {
            this.$emit('closeVisaModal');
          })
        }
      },
      fetchVisaById() {
        getVisa(this.id).then(res => {
          this.visa = res.data.data;
          this.visaCountry = this.visa.country;
        })
      },
      SearchVisaCountry(search) {
        this.countryTimeOut = null;
        this.countryTimeOut = setTimeout(() => {
          getCountries({
            search
          }).then(res => {
            this.countries = res.data.data.data;
          })
        }, 500);
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchVisaById();
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>