<template>
  <div>
    <validation-observer ref="refFormObserver">
      <validation-provider #default="validationContext" :name="$t('message.Countries')" rules="required">
        <div class="w-100">
          <label for="country">{{$t('message.Countries')}}</label>
          <v-select :options="countries" label="name_uz" v-model="selectedCountry" @search="getSearchCountry">
          </v-select>
        </div>
        <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
      </validation-provider>
      <validation-provider #default="validationContext" name="Insurance Actual Price" rules="required">
        <b-form-group :label="$t('message.Actual_price')" for="actual_price" class="mt-2">
          <b-form-input autofocus v-model="insurance.actual_price"></b-form-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </b-form-group>
      </validation-provider>

      <validation-provider #default="validationContext" name="Insurance Market Price" rules="required">
        <b-form-group :label="$t('message.Market_price')" for="market_price">
          <b-form-input autofocus v-model="insurance.market_price"></b-form-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </b-form-group>
      </validation-provider>

      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('message.Currency')" rules="required"
          class="w-100 mt-1">
          <label for="currency">{{ $t('message.Currency') }}</label>
          <b-form-select v-model="insurance.currency_id" :options="currencies" text-field="name" value-field="id"
            class="w-100" />
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>

      <validation-provider #default="validationContext" :name="$t('message.Start_date')" rules="required"
        class="w-100 mt-1">
        <label for="start_date">{{$t('message.Start_date')}}</label>
        <input type="date" class="form-control mb-2" v-model="insurance.start_date">
        <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
      </validation-provider>

      <validation-provider #default="validationContext" :name="$t('message.End_date')" rules="required"
        class="w-100 mt-1">
        <label for="end_date">{{$t('message.End_date')}}</label>
        <input type="date" class="form-control mb-2" v-model="insurance.end_date">
        <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
      </validation-provider>

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addInsurance,
    editInsurance,
    getInsuranceById
  } from "@/api/insurance";
  import vSelect from 'vue-select'
  import {
    getCountries
  } from '@/api/countries/countries'
  // import { getBranchById } from '@/api/branchs/branchs'

  export default {
    name: 'FormInsurance',
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      tours: '',
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        insurance: {
          id: null,
          actual_price: '',
          market_price: '',
          start_date: '',
          end_date: '',
          to_country_id: null,
          client_id: this.$route.params.id,
        },
        selectedCountry: null,
        countries: [],
        disabled: false,
      }
    },
    computed: {
      currencies() {
        return this.$store.state.settings.currencies;
      }
    },
    methods: {
      // fetchClients() {
      //   getBranchById().then(res => {
      //     this.insurance = res.data.data
      //   })
      // },
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 1000)

          this.insurance.id = this.id;
          this.insurance.to_country_id = this.selectedCountry.id;
          let data = {...this.insurance}
          if (this.id) {
            // eslint-disable-next-line no-unused-vars
            editInsurance(this.insurance).then(res => {
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addInsurance(this.insurance).then(res => {
              this.$emit('closeModal')
            })
          }
        }
      },
      fetchInsuranceById() {
        getInsuranceById(this.id).then(res => {
          this.insurance = res.data.data;
        })
      },
      getSearchCountry(search) {
        if (search) {
          this.timesOut = setTimeout(() => {
            getCountries({
              search
            }).then(res => {
              this.countries = res.data.data.data
            })
          }, 600)
        }
      },
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchInsuranceById()
            // this.fetchClients()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>