<template>
  <div class="w-100">
    <!-- <b-overlay :show="loading" no-wrap spinner-variant="primary"></b-overlay> -->
    <div class="w-100">
      <b-button
        class="mb-1"
        variant="warning"
        @click="goBack"
      >
        {{$t('message.back')}}
      </b-button>
    </div>
    <b-card no-body>
      <b-tabs justified content-class="mt-2" fill pills card>
        <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>{{$t('message.Client_details')}}</span>
          </template>
          <b-card-text>
            <ClientDetail />
          </b-card-text>
        </b-tab>
        <!-- <b-tab v-if="!checkRoleUser">
          <template #title>
            <feather-icon icon="BriefcaseIcon" />
            <span>{{$t('message.Tours')}}</span>
          </template>
          <b-card-text>
            <ClientTour />
          </b-card-text>
        </b-tab> -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>{{$t('message.Avia Tickets')}}</span>
          </template>
          <b-card-text>
            <Tickets />
          </b-card-text>
        </b-tab> -->
        <b-tab v-if="!checkRoleUser">
          <template #title>
            <feather-icon icon="CreditCardIcon" />
            <span>{{$t('message.Visa')}}</span>
          </template>
          <b-card-text>
            <Visa />
          </b-card-text>
        </b-tab>
        <!-- <b-tab v-if="!checkRoleUser">
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>{{$t('message.Hotels')}}</span>
          </template>
          <b-card-text>
            <Hotels />
          </b-card-text>
        </b-tab> -->
        <!-- <b-tab v-if="!checkRoleUser">
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>{{$t('message.Insurance')}}</span>
          </template>
          <b-card-text>
            <Insurance />
          </b-card-text>
        </b-tab> -->
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import ClientDetail from '@/views/directories/clients/components/ClientDetail'
  import ClientTour from "@/views/directories/clients/components/ClientTour";
  import Tickets from "@/views/directories/clients/components/avia-tickets/Tickets";
  import Hotels from "@/views/directories/clients/components/hotels/Hotels";
  import Insurance from "@/views/directories/clients/components/insurance/Insurance";
  import Visa from "@/views/directories/clients/components/visa/Visa";
  import {mapGetters} from 'vuex'

  export default {
    name: 'detail',
    components: {Insurance, Hotels, Tickets, ClientTour, ClientDetail, Visa },
    mounted() {
      this.$store.dispatch('settings/fetchCountries');
      this.$store.dispatch('settings/fetchCurrencies');
    },
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if(this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'aviaseller')
        }
        return false
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped>

</style>
