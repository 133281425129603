<template>
  <div class="w-100">
    <b-overlay :show="loading" no-wrap spinner-variant="primary"></b-overlay>
    <validation-observer ref="observer">
      <b-row class="align-items-end">
        <b-col cols="9">
          <div class="w-100">
            <v-select v-model="hotel.hotel" :options="hotels" label="name" @search="searchHotel" class="w-100">
            </v-select>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="d-flex w-100 justify-content-end">
            <b-button variant="primary" size="sm" @click="addNewHotelModal=true">
              <b-icon icon="plus"></b-icon>
              {{$t('message.Add_hotel')}}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="6">
          <ValidationProvider #default="validationContext" :name="$t('message.Start_date')" rules="required">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('message.Start_date')}}
              </p>
              <div class="wrapper-form">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <input type="date" class="form-control" v-model="hotel.start_date" >
              </div>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col cols="6">
          <ValidationProvider #default="validationContext" :name="$t('message.End_date')" rules="required">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('message.End_date')}}
              </p>
              <div class="wrapper-form">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <input type="date" v-model="hotel.end_date" class="form-control">
              </div>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" name="Visa Сумма брутто" rules="required|numeric"
              class="w-100 mt-1">
              <label for="actual_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                </span>
              </label>
              <b-input v-model="hotel.actual_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" name="Visa Сумма нетто" rules="required|numeric"
              class="w-100 mt-1">
              <label for="market_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt' type="solid"></box-icon><span>{{$t('message.Net_amount')}}</span>
                </span>
              </label>
              <b-input v-model="hotel.market_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <div class="w-100 mt-1">
        <validation-provider #default="validationContext" :name="$t('message.Currency')" rules="required"
          class="w-100 mt-1">
          <label for="currency">{{ $t('message.Currency') }}</label>
          <v-select
            v-model="hotel.currency_id"
            :options="currencies"
            label="name"
          ></v-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>

      <div class="w-100 mt-3 d-flex justify-content-end">
        <b-button variant="warning" @click="$emit('closeTourHotels')" class="mr-1">
          {{$t('message.back')}}
        </b-button>
        <b-button variant="primary" :disabled="disabledButton" @click="passHotels">
          {{$t('message.Add')}}
        </b-button>
      </div>

      <b-modal ok-only size="lg" v-model="addNewHotelModal" hide-footer no-close-on-backdrop>
        <HotelsForm :id="id" :countries="countries" @closeModal="closeModal" />
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver,
    extend
  } from 'vee-validate'
  import {required} from '@validations'
  import HotelsForm from '@/views/directories/hotels/components/HotelsForm'
  import {getCountries} from '@/api/countries/countries'
  import vSelect from 'vue-select'
  import {
    getHotels,
    storeClientHotel,
    showClientHotel,
    updateClientHotel,
  } from '@/api/hotels'
  import * as rules from 'vee-validate/dist/rules';
  import {
    messages
  } from 'vee-validate/dist/locale/ru.json';

  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    });
  });

  export default {
    name: 'HotelForm',
    components: {
      HotelsForm,
      vSelect,
      ValidationProvider,
      ValidationObserver
    },
    props: {
      hotelId: {
        default: null,
      }
    },
    data() {
      return {
        required,
        addNewHotelModal: false,
        countries: [],
        id: null,
        hotels: [],
        selectedHotels: null,
        clientHotelChoice: null,
        hotel: {
          id: null,
          hotel_id: null,
          hotel: null,
          start_date: null,
          end_date: null,
          actual_price: null,
          market_price: null,
          currency_id: null,
          client_id: this.$route.params.id,
        },
        searchTimeOut: null,
        loading: false,
        disabledButton: false,
      }
    },
    mounted() {
      this.fetchCountries();
    },
    computed: {
      currencies() {
        return this.$store.state.settings.currencies;
      }
    },
    methods: {
      fetchCountries() {
        getCountries().then(res => {
          this.countries = res.data.data.data;
        })
      },
      closeModal(data = null) {
        this.addNewHotelModal = false;
        if (data) {
          this.hotel.hotel = data;
        }
      },
      searchHotel(search) {
        if (search) {
          this.searchTimeOut = null;
          this.searchTimeOut = setTimeout(() => {
            getHotels({
              search
            }).then(res => {
              this.hotels = res.data.data;
            })
          }, 400)
        }
      },
      deselectHotel(data) {
        if (this.clientHotelChoice) {
          if (data.id === this.clientHotelChoice) {
            this.clientHotelChoice = null;
          }
        }
      },
      passHotels() {
        const isValid = this.$refs.observer.validate();
        this.disabledButton = true;
        this.loading = true;
          setTimeout(() => {
            this.disabledButton = false;
        this.loading = false;

          }, 1000)
        if (isValid) {
          
          this.hotel.hotel_id = this.hotel.hotel.id;
          delete this.hotel.hotel
          this.hotel.currency_id = this.hotel.currency_id.id
          let fd = this.hotel;

          if(this.hotelId === null) {
            storeClientHotel(fd).then(res => {
              this.$emit('closeTourHotels');
            }) 
          } else {
            
            updateClientHotel(fd).then( () => {
              this.$emit('closeTourHotels');
            })
          }
        }
      }
    },
    watch: {
      hotelId: {
        immediate: true,
        handler(val) {
          if(val) {
            showClientHotel(this.hotelId).then( res => {
              let data = res.data.data;
              this.hotel = {
                id: data.id,
                hotel_id: data.hotel_id,
                hotel: data.hotel,
                start_date: data.start_date,
                end_date: data.end_date,
                actual_price: parseInt(data.actual_price),
                market_price: parseInt(data.market_price),
                currency_id: data.currency,
                client_id: data.client_id,
              }
            })
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>