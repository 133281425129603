<template>
  <div>
    <validation-observer
        ref="refFormObserver"
    >
      <b-row>
        <b-col cols="8">
          <validation-provider
              #default="validationContext"
              :name="$t('message.Name')"
              rules="required"
          >
            <b-form-group :label="$t('message.Name')" for="name">
              <b-form-input autofocus v-model="tours.tour.name"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <validation-provider #default="validationContext" :name="$t('message.Partner')" rules="required" class="w-100 mt-1">
            <div class="w-100">
              <label>{{$t('message.Partners')}}</label>
              <b-form-select
                  v-model="tours.tour.partner_id"
                  :options="partners"
                  text-field="name"
                  value-field="id"
                  style="height: 40px"
                  class="w-100"
              />
            </div>
            <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Start_date')" rules="required" class="w-100 mt-1">
            <label for="start_date">{{$t('message.Start_date')}}</label>
            <input type="date" class="form-control" v-model="tours.tour.start_date">
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.End_date')" rules="required" class="w-100 mt-1">
            <label for="end_date">{{$t('message.End_date')}}</label>
            <input type="date" class="form-control" v-model="tours.tour.end_date">
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Gross_amount')" rules="required|numeric" class="w-100 mt-1">
              <label for="actual_price">{{$t('message.Gross_amount')}}</label>
              <b-input v-model="tours.tour.actual_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
              <label for="market_price">{{$t('message.Net_amount')}}</label>
              <b-input v-model="tours.tour.market_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 mt-2">
        <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
          <label for="currency">{{$t('message.Currency')}}</label>
        <b-form-select :options="currencies" text-field="name" value-field="id" v-model="tours.tour.currency_id"></b-form-select>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>
      </div>
      <hr>
      <div class="w-100 mt-2">
        <div class="w-100 d-flex justify-content-between">
          <h4>{{$t('message.Hotels')}}</h4>
          <b-button variant="primary" size="sm" @click="hotelModal=true">
            <b-icon icon="plus"></b-icon> <span style="font-size: small">{{$t('message.Add_hotel')}}</span>
          </b-button>
        </div>
        <validation-provider
            #default="validationContext"
            :name="$t('message.HotelName')"
            rules="required"
        >
          <b-form-group :label="$t('message.HotelName')" for="name">
            <b-form-input autofocus v-model="tours.hotel.name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>
      </div>
      <b-row class="mt-2">
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Gross_amount')" rules="required|numeric" class="w-100 mt-1">
            <label for="actual_price">{{$t('message.Gross_amount')}}</label>
            <b-input v-model="tours.hotel.actual_price"></b-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
            <label for="market_price">{{$t('message.Net_amount')}}</label>
            <b-input v-model="tours.hotel.market_price"></b-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </b-col>
      </b-row>
      <hr>
<!--      Ticket Start-->
      <h4>{{$t('message.Avia Tickets')}}</h4>
      <validation-provider
          #default="validationContext"
          :name="$t('message.Avia Tickets')"
          rules="required" class="w-100">
        <label class="mt-2">{{$t('message.Avia Tickets')}}</label>
        <b-form-select
            v-model="tours.tour.avia_ticket_id"
            :options="avia_tickets"
            text-field="name"
            value-field="id"
            @input="getTicketSelect"
            style="height: 40px"
            class="w-100"
        />
        <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
      </validation-provider>
      <b-row class="mt-2">
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Gross_amount')" rules="required|numeric" class="w-100 mt-1">
            <label for="actual_price">{{$t('message.Gross_amount')}}</label>
            <b-input v-model="tours.avia_sales.actual_price"></b-input>
            <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
            <label for="market_price">{{$t('message.Net_amount')}}</label>
            <b-input v-model="tours.avia_sales.market_price"></b-input>
            <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
          </validation-provider>
        </b-col>
      </b-row>
<!--      Ticket End-->
      <hr>
      <h4>{{$t('message.Visa')}}</h4>
      <b-row>
        <b-col cols="8">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Title')" rules="required" class="w-100 mt-1">
              <label for="title">{{$t('message.Title')}}</label>
              <b-input v-model="tours.visa.title"></b-input>
              <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="4">
          <validation-provider #default="validationContext" :name="$t('message.Countries')" rules="required" class="w-100 mt-1">
            <label class="mt-2">{{$t('message.Countries')}}</label>
<!--            <b-form-select-->
<!--                v-model="tours.visa.country_id"-->
<!--                :options="countries"-->
<!--                text-field="name_uz"-->
<!--                value-field="id"-->
<!--                class="w-100"-->
<!--            />-->
            <v-select
                :options="countries"
                label="name_ru"
                v-model="visaCountry"
                @search="SearchVisaCountry"
            ></v-select>
            <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Gross_amount')"
                                 rules="required|numeric" class="w-100 mt-1">
              <label for="actual_price">{{$t('message.Gross_amount')}}</label>
              <b-input v-model="tours.visa.actual_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
              <label for="market_price">{{$t('message.Net_amount')}}</label>
              <b-input v-model="tours.visa.market_price"></b-input>
              <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <hr>
      <h4>{{$t('message.Insurance')}}</h4>
      <div class="w-100">
        <b-row>
          <b-col cols="6">
            <validation-provider #default="validationContext" :name="$t('message.Start_date')" rules="required" class="w-100 mt-1">
              <label for="start_date">{{$t('message.Start_date')}}</label>
              <input type="date" class="form-control" v-model="tours.insurance.start_date">
              <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider #default="validationContext" :name="$t('message.End_date')" rules="required" class="w-100 mt-1">
              <label for="end_date">{{$t('message.End_date')}}</label>
              <input type="date" class="form-control" v-model="tours.insurance.end_date">
              <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <div class="w-100 mt-1">
              <validation-provider #default="validationContext" :name="$t('message.Gross_amount')" rules="required|numeric" class="w-100 mt-1">
                <label for="actual_price">{{$t('message.Gross_amount')}}</label>
                <b-input v-model="tours.insurance.actual_price"></b-input>
                <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="w-100 mt-1">
              <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
                <label for="market_price">{{$t('message.Net_amount')}}</label>
                <b-input v-model="tours.insurance.market_price"></b-input>
                <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>
      <b-modal v-model="hotelModal" ok-only hide-footer size="lg" no-close-on-backdrop>
        <TourHotelsForm @closeTourHotels="closeTourHotels" @passHotels="passHotels" />
      </b-modal>

    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, numeric } from '@validations'
import {addTours, editTours, getToursById} from "@/api/tours";
import {createTicketsById} from "@/api/avia-tickets";
import TourHotelsForm from '@/views/directories/clients/includes/TourHotelsForm'
import { getPartners } from '@/api/partners'
import { getCountries } from '@/api/countries/countries'
import vSelect from 'vue-select';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/ru.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

export default {
  name: 'TourForm',
  components: {
    TourHotelsForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props:{
    new_ticket:{
      default: null
    },
    id:{
      default:null
    }
  },
  data() {
    return {
      required,
      numeric,
      tours: {
        tour: {
          id: null,
          client_id: null,
          name: '',
          start_date: null,
          end_date: null,
          avia_ticket_id:'',
          partner_id: null,
          actual_price: null,
          market_price: null,
          currency_id: null,
        },
        avia_sales: {
          actual_price: null,
          market_price: null,
        },
        visa: {
          title: '',
          actual_price: null,
          market_price: null,
          country_id: null,
        },
        insurance: {
          start_date:null,
          end_date: null,
          actual_price: null,
          market_price: null,
        },
        hotel: {
          id: null,
          name: '',
          actual_price: null,
          market_price: null,
        },
        hotels: [],
      },
      avia_tickets:[],
      formModalActive: false,
      hotelModal: false,
      partners: [],
      visaCountry: null,
      countries: [],
      countryTimeOut: null,
      disabled: false,
    }
  },
  mounted() {
    createTicketsById().then(res => {
      this.avia_tickets = res.data.data
      this.avia_tickets.unshift({name:'add_ticket', id:0})
    })
    this.fetchPartners();
  },
  computed: {
    currencies() {
      return this.$store.state.settings.currencies;
    }
  },
  methods: {
    closeTourHotels() {
      this.hotelModal = false;
    },
    fetchTours() {
      getToursById(this.id).then(res => {
        this.tours = res.data.data;
      })
    },
    fetchPartners() {
      getPartners({pageSize: 50}).then(res => {
        this.partners = res.data.data.data;
      })
    },
    getTicketSelect(){
      if(this.tours.tour.avia_ticket_id === 0){
        this.$emit("openTicketModal")
      }
    },
    closeModal(){
      this.formModalActive = false
    },
    passHotels(hotels, clientHotel) {
      this.tours.hotels = hotels.map(item => item.id);
      this.tours.hotel.name = clientHotel.name;
      this.tours.hotel.id = clientHotel.id;
      this.hotelModal = false;
    },
    handleSubmit() {
      const isValid = this.$refs.refFormObserver.validate()
      if(isValid) {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)

        this.tours.tour.client_id = this.$route.params.id;
        this.tours.visa.country_id = this.visaCountry.id;
        addTours(this.tours).then(res => {
          this.$emit('closeModal');
        })
      }
    },
    SearchVisaCountry(search) {
      this.countryTimeOut = null;
      this.countryTimeOut = setTimeout(() => {
        getCountries({search}).then(res => {
          this.countries = res.data.data.data;
        })
      },500);
    }
  },
  watch: {
    id:{
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchTours()
        }
      }
    },
    new_ticket:{
      immediate: true,
      handler(val){
        if(val){
          this.avia_tickets.push(val)
          this.tours.tour.avia_ticket_id = val.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
