<template>
  <div class="w-100">
    <b-row v-if="client !== null">
      <!-- User Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="client.avatar" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ client.full_name }}
              </h4>
              <span v-if="client.branch" class="card-text">{{ client.branch.name }}</span>
            </div>

            <div v-if="client !== null">
          </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('message.Passport_serial')}}</span>
            </th>
            <td class="pb-50">
              {{ client.passport_serial }}
            </td>
          </tr> -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('message.Passport_number')}}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ client.passport_number }}
            </td>
          </tr> -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('message.Given_date')}}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ client.given_date }}
            </td>
          </tr> -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('message.Expire_date')}}</span>
            </th>
            <td class="pb-50">
              {{ client.expire_date }}
            </td>
          </tr> -->
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('message.Birth_date')}}</span>
            </th>
            <td>
              {{ client.birthdate }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapState,} from 'vuex';
  export default {
    name: 'ClientDetail',
    created() {
      this.fetchClientDetail({
        id: this.$route.params.id
      });
    },
    computed: {
      ...mapState({
        client: state => state.client.clientDetail
      }),
      
    },
    methods: {
      ...mapActions('client', ['fetchClientDetail'])
    }
  }
</script>