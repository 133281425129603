<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="w-100 d-flex justify-content-start">
          <b-button variant="primary" class="mb-1" @click="handleAdd">
            <b-icon icon="plus"></b-icon>{{$t('message.AddNewTour')}}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-card>
      <b-table :fields="fields" :items="tours" responsive :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
        </template>

        <template #cell(ticket)="{item}">
          {{item.sale.ticket.name}} <br>
          <span class="text-primary">
            <box-icon type="solid" name="plane-take-off" size="sm"></box-icon>
            <span class="font-small-3">
              {{item.sale.ticket.fly_datetime | onlyDate}} {{item.sale.ticket.fly_datetime | onlyTime}}
            </span>
          </span>
          <br>
          <span class="text-warning">
            <span class="d-flex">
              <box-icon name='plane-land' type='solid'></box-icon>
              <span class="font-small-3">
                {{item.sale.ticket.arrive_datetime | onlyDate}} {{item.sale.ticket.arrive_datetime | onlyTime}}
              </span>
            </span>
          </span>
        </template>
        <template #cell(visa)="{item}">
          <div class="d-flex">
            <box-icon name='visa' type='logo'></box-icon>
            <span v-if="item.sale.visa">
              {{item.sale.visa.title}}
            </span>
          </div>
          <p class="d-flex mt-1" v-if="item.sale.visa">
            <box-icon name='building'></box-icon> {{item.sale.visa.country.name_uz}}
          </p>
        </template>
        <template #cell(insurance)="{item}">
          <div class="d-flex">
            <box-icon type='solid' name='calendar-plus'></box-icon>
            <span>
              {{item.sale.insurance.start_date | onlyDate}}
            </span>
          </div>
          <div class="d-flex">
            <box-icon type='solid' name='calendar-minus'></box-icon>
            <span>
              {{item.sale.insurance.end_date | onlyDate}}
            </span>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <Word :dataUser="data.item" />
            </div>
            <div>
              <b-button
                class="ml-1"
                variant="outline-success"
                size="sm"
                @click="handleShow(data.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal hide-footer size="lg" v-model="formModalActive" no-close-on-backdrop scrollable ok-only>
      <TourForm :id="id" :new_ticket="new_ticket" :avia_tickets="avia_tickets" @closeModal="closeModal"
        @openTicketModal="openTicketModal" />

    </b-modal>
    <b-modal hide-footer size="xl" v-model="modalActive" no-close-on-backdrop ok-only>
      <Form :id="id" @closeModal="closeTicketModal" />

    </b-modal>
    <b-modal hide-footer size="lg" no-close-on-backdrop v-model="existingTourModal" scrollable>
      <AddExistingTour @closeAddExistingModal="closeAddExistingModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import {
    deleteTours,
    getTours
  } from "@/api/tours";
  import ToursModal from "@/views/tours/components/ToursModal";
  import TicketsForm from "@/views/directories/avia-tickets/components/TicketsForm";
  import TourForm from '@/views/directories/clients/includes/TourForm'
  import moment from 'moment';
  import AddExistingTour from '@/views/directories/clients/includes/AddExistingTour'
  import Form from '../components/avia-tickets/Form';
  import Word from './word.vue';

  export default {
    name: 'Index',
    components: {
      AddExistingTour,
      TourForm,
      moment,
      ToursModal,
      TicketsForm,
      Form,
      Word
    },
    filters: {
      onlyDate(val) {
        return moment(val).format('D-MM-Y');
      },
      onlyTime(val) {
        return moment(val).format('HH:mm');
      }
    },
    data() {
      return {
        new_ticket: null,
        id: null,
        avia_tickets: [],
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'author.full_name',
            label: this.$t('message.Author')
          },
          {
            key: "hotel.name",
            label: this.$t('data.hotel')
          },
          {
            key: 'start_date',
            label: this.$t('StartDate'),
          },
          {
            key: "end_date",
            label: this.$t('EndDate')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        tours: [],
        formModalActive: false,
        modalActive: false,
        existingTourModal: false,
        busy: true,
      }
    },
    created() {
      this.fetchTours()
    },
    methods: {
      fetchTours() {
        getTours({
          client_id: this.$route.params.id
        }).then(response => {
          this.tours = response.data.data
          this.busy = false;
          this.tours.forEach(item => {
            item.start_date = moment(item.start_date).format('YYYY-MM-DD')
            item.end_date = moment(item.end_date).format('YYYY-MM-DD')
          })
          // this.tours.forEach(item => {
          //   item.sale = item.sale[0]
          // });
        })
      },
      handleAdd() {
        this.id = null;
        this.$router.push('/tours/add-tours/' + this.$route.params.id)
      },
      handleEdit(id) {
        this.id = id;
        this.$router.push({
          name: 'update-tours',
          params: {
            tourId: id
          }
        })
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchTours()
      },
      closeTicketModal(data = null) {
        if (data) {
          this.new_ticket = data
        }
        this.modalActive = false
      },
      closeAddExistingModal() {
        this.existingTourModal = false;
        this.fetchTours()
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteTours({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchTours()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      openTicketModal() {
        this.modalActive = true
      },
      handleShow(id) {
        this.$router.push({name: 'Tour-Details', params: {tourDetailsId: id}})
      }
    }
  }
</script>