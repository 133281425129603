import request from '../../libs/axios'


export function getVisas(params) {
  return request({
    url: '/api/visa',
    method: 'get',
    params,
  });
}

export function storeVisa(data) {
  return request({
    url:'/api/visa',
    method: 'post',
    data,
  })
}

export function showVisa(id) {
  return request({
    url:`/api/visa/${id}`,
    method:'get',
  });
}

export function updateVisa(data) {
  return request({
    url:`/api/visa/${data.id}`,
    method:'put',
    data
  });
}

export function deleteVisa(data) {
  return request({
    url: `/api/visa/${data.id}`,
    method:'delete',
  })
}


export function getVisaExcel(params) {
  return request({
    url:'/api/visa-excel-reports',
    method:'get',
    params,
    responseType: 'blob',
  })
}

export function uploadVisaResult(data) {
  return request({
    url: '/api/visa-upload-result',
    method: 'post',
    data,
    headers: {
      'Content-Type': "multipart/form-data",
    }
  })
}

export function getS3PermanentFile(params) {
  return request({
    url: '/api/get-s3-file',
    method: 'get',
    params,
    // responseType: 'blob',
  })
}