<template>
  <div>
    <b-row>
      <b-col cols="8">
        <validation-observer ref="refFormObserver">
          <validation-provider #default="validationContext" name="Full Name" rules="required">
            <b-form-group :label="$t('message.Name')" for="name">
              <b-form-input autofocus v-model="tickets.name"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <div class="w-100">
            <validation-provider #default="validationContext" :name="$t('message.Fly_datetime')" rules="required"
              class="w-100 mt-1">
              <label for="fly_datetime">{{$t('message.Fly_datetime')}}</label>
              <input type="date" class="form-control mb-2" v-model="tickets.fly_datetime">
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>

          <div class="w-100">
            <validation-provider #default="validationContext" :name="$t('message.Arrive_datetime')" rules="required"
              class="w-100 mt-1">
              <label for="arrive_datetime">{{$t('message.Arrive_datetime')}}</label>
              <input type="date" class="form-control mb-2" v-model="tickets.arrive_datetime">
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>

          <div class="w-100">
            <b-form-group :label="$t('message.Limit')">
              <b-form-input autofocus v-model="tickets.limit"></b-form-input>
            </b-form-group>
          </div>
          <validation-provider #default="validationContext" name="Type" rules="required">
            <b-form-group :label="$t('message.Type')">
              <b-form-select :options="types" text-field="label" value-field="value" v-model="tickets.type">
              </b-form-select>
              <span>
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" name="Visa Сумма брутто" rules="required|numeric"
              class="w-100 mt-1">
              <label for="actual_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                </span>
              </label>
              <b-input v-model="tickets.sales.actual_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" name="Visa Сумма нетто" rules="required|numeric"
              class="w-100 mt-1">
              <label for="market_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt' type="solid"></box-icon><span>{{$t('message.Net_amount')}}</span>
                </span>
              </label>
              <b-input v-model="tickets.sales.market_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('data.payment_methods')" rules="required"
              class="w-100 mt-1">
              <label for="currency">{{ $t('data.payment_methods') }}</label>
              <b-form-select v-model="tickets.sales.currency_id" :options="currencies" text-field="name"
                value-field="id" class="w-100" />
              {{ validationContext.errors[0] }}
            </validation-provider>
          </div>
          <div class="w-100 d-flex justify-content-end mt-3">
            <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
              {{$t('message.Save')}}
            </b-button>
            <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
              {{$t('message.Cancel')}}
            </b-button>
          </div>
        </validation-observer>
      </b-col>
      <b-col cols="4">
        <h4>{{$t('message.Countries')}}</h4>
        <CountriesLine :timelineCountries="timelineCountries" @removeCountry="removeCountry" />
        <div class="w-100 mt-2">
          <v-select :options="countries" label="name_uz" v-model="selectedCountry" @search="getSearchCountry">
          </v-select>
        </div>
        <div class="w-100 d-flex justify-content-end mt-1">
          <b-button variant="primary" @click="addCountry">
            <b-icon icon="plus"></b-icon>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addTickets,
    editTickets,
    getTicketsById
  } from '@/api/avia-tickets'
  import CountriesLine from '@/views/directories/clients/components/avia-tickets/CountriesLine'
  import vSelect from 'vue-select';
  import {
    getCountries
  } from '@/api/countries/countries'

  // Object.keys(rules).forEach(rule => {
  //   extend(rule, {
  //     ...rules[rule],
  //     message: messages[rule]
  //   });
  // });

  export default {
    name: 'FormTickets',
    components: {
      CountriesLine,
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      new_ticket: {
        default: null
      },
      id: {
        default: null
      }
    },
    computed: {
      currencies() {
        return this.$store.state.settings.currencies;
      }
    },
    data() {
      return {
        required,
        tickets: {
          id: null,
          name: '',
          fly_datetime: '',
          arrive_datetime: '',
          limit: '',
          type: 'public',
          tikcet_countries: [],
          sales: {
            market_price: null,
            actual_price: null,
            currency_id: null,
            client_id: this.$route.params.id,
          }
        },
        types: [{
            label: "Public",
            value: 'public'
          },
          {
            label: "Private",
            value: 'private'
          },
        ],
        selectedCountry: null,
        avia_tickets: [],
        formModalActive: false,
        hotelModal: false,
        partners: [],
        visaCountry: null,
        countries: [],
        countryTimeOut: null,
        timelineCountries: [],
        disabled: false,
      }
    },
    methods: {
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 1000)

          if (this.id) {
            this.tickets.id = this.id;
            this.tickets.tikcet_countries = this.timelineCountries.map(item => item.id)
            delete this.tickets.countries;
            // eslint-disable-next-line no-unused-vars
            editTickets(this.tickets).then(res => {
              this.$emit('closeModal')
            })
          } else {
            this.tickets.tikcet_countries = this.timelineCountries.map(item => item.id)
            // eslint-disable-next-line no-unused-vars
            addTickets(this.tickets).then(res => {
              this.$emit('closeModal', res.data.data)
            })
          }
        }
      },
      fetchTicketsById() {
        getTicketsById(this.id).then(res => {
          this.tickets = res.data.data;
          this.timelineCountries = this.tickets.countries;
        })
      },
      getSearchCountry(search) {
        if (search) {
          this.timesOut = setTimeout(() => {
            getCountries({
                search
              })
              .then(res => {
                this.countries = res.data.data.data
              })
          }, 600)
        }
      },
      addCountry() {
        this.timelineCountries.push(this.selectedCountry);
        this.selectedCountry = null;
      },
      removeCountry(index) {
        this.timelineCountries.splice(index, 1)
      },
      closeTourHotels() {
        this.hotelModal = false;
      },
      fetchTours() {
        getToursById(this.id).then(res => {
          this.tours = res.data.data;
        })
      },
      fetchPartners() {
        getPartners({
          pageSize: 50
        }).then(res => {
          this.partners = res.data.data.data;
        })
      },
      getTicketSelect() {
        if (this.tours.tour.avia_ticket_id === 0) {
          this.$emit("openTicketModal")
        }
      },
      closeModal() {
        this.formModalActive = false
      },
      passHotels(hotels, clientHotel) {
        this.tours.hotels = hotels.map(item => item.id);
        this.tours.hotel.name = clientHotel.name;
        this.tours.hotel.id = clientHotel.id;
        this.hotelModal = false;
      },
      SearchVisaCountry(search) {
        this.countryTimeOut = null;
        this.countryTimeOut = setTimeout(() => {
          getCountries({
            search
          }).then(res => {
            this.countries = res.data.data.data;
          })
        }, 500);
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchTicketsById()
          }
        }
      },
      new_ticket: {
        immediate: true,
        handler(val) {
          if (val) {
            this.avia_tickets.push(val)
            this.tours.tour.avia_ticket_id = val.id
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>