<template>
  <div>
    <div class="w-100 d-flex justify-content-start">
      <b-button variant="primary" class="mb-1" @click="handleAdd">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table :fields="fields" :items="insurance" responsive :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button
                variant="outline-success"
                size="sm"
                @click="handleShow(data.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal hide-footer v-model="insuranceFormActive" no-close-on-backdrop>
      <FormInsurance :id="id" :tours="tours" @closeModal="closeInsuranceModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import {
    deleteInsurance,
    getInsurance
  } from "@/api/insurance";
  import {
    createToursById
  } from "@/api/tours";
  import FormInsurance from "@/views/directories/clients/components/insurance/Form";

  export default {
    name: 'Insurance',
    components: {
      FormInsurance
    },
    data() {
      return {
        id: null,
        insurance: [],
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'start_date',
            label: this.$t('message.Start_date'),
          },
          {
            key: 'end_date',
            label: this.$t('message.End_date'),
          },
          {
            key: 'actual_price',
            label: this.$t('message.Actual_price'),
          },
          {
            key: "market_price",
            label: this.$t('message.Market_price'),
          },
          {
            key: 'country.name_ru',
            label: this.$t('message.Countries'),
          },
          {
            key: 'payment_type.name_ru',
            label: this.$t('data.payment_type')
          },
          {
            key: 'author.name',
            label: this.$t('message.Author'),
          },
          {
            key: 'action',
            label: this.$t('message.Action'),
          },
        ],
        tours: [],
        insuranceFormActive: false,
        busy: true,
      }
    },
    created() {
      this.fetchInsurance()
    },
    methods: {
      fetchInsurance() {
        getInsurance({
          client_id: this.$route.params.id,
          relations: true
        }).then(response => {
          this.insurance = response.data.data;
          this.insurance.forEach( item => item.actual_price = parseInt(item.actual_price))
          this.insurance.forEach( item => item.market_price = parseInt(item.market_price));
          this.busy = false;
        })
      },
      handleAdd() {
        this.id = null;
        // this.insuranceFormActive = true
        // createToursById().then(res => {
        //   this.tours = res.data.data
        // })
        this.$router.push('/insurance/insurance-add/' + this.$route.params.id)
      },
      handleEdit(id) {
        this.$router.push({name: 'insurance-update', params: {insuranceId: id}})
      },
      closeInsuranceModal() {
        this.insuranceFormActive = false;
        this.fetchInsurance()
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteInsurance({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchInsurance()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      handleShow(id) {
        this.$router.push({name: "insurance-details", params: {insuranceDetailsId: id}})
      }
    }
  }
</script>

<style scoped>

</style>