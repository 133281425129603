<template>
  <div class="w-100">
    <validation-observer ref="observer">
      <h3>{{$t('message.Existing_tour')}}</h3>
      <validation-provider
          #default="validationContext"
          :name="$t('message.Tours')"
          rules="required"
      >
        <div class="w-100">
          <label class="mt-2"><box-icon type='solid' name='plane-alt'></box-icon>{{$t('message.Tours')}}</label>
          <b-form-select
              v-model="newTour.tour.id"
              :options="tours"
              text-field="name"
              value-field="id"
              class="w-100"
              @input="tourSelected"
          />
        </div>
        <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
      </validation-provider>
      <b-row>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Gross_amount')" rules="required|numeric" class="w-100 mt-1">
              <label for="actual_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                </span>
              </label>
              <b-input v-model="newTour.tour.actual_price"></b-input>
              <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
              <label for="market_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt' type='solid' ></box-icon> <span>{{$t('message.Net_amount')}}</span>
                </span>
              </label>
              <b-input v-model="newTour.tour.market_price"></b-input>
              <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <div v-if="selectedTour" class="w-100 mt-2">
        <div class="d-flex">
          <box-icon name='plane' type='solid' ></box-icon><h4>Avia Ticket [ {{selectedTour.avia_ticket.name}} ]</h4>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="w-100 mt-1">
              <validation-provider #default="validationContext" name="Avia Ticket Сумма брутто" rules="required|numeric" class="w-100 mt-1">
                <label for="actual_price">
                  <span class="d-flex">
                    <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                  </span>
                </label>
                <b-input v-model="newTour.avia_sales.actual_price"></b-input>
                <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="w-100 mt-1">
              <validation-provider #default="validationContext" name="Avia Ticket Сумма нетто" rules="required|numeric" class="w-100 mt-1">
                <label for="market_price">
                  <span class="d-flex">
                    <box-icon name='wallet-alt' type="solid"></box-icon> <span>{{$t('message.Net_amount')}}</span>
                  </span>
                </label>
                <b-input v-model="newTour.avia_sales.market_price"></b-input>
                <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="selectedTour" class="w-100 mt-2">
        <div class="d-flex">
          <box-icon name='hotel' ></box-icon><h4>{{$t('message.Hotels')}}</h4>
        </div>
        <div class="w-100 mt-1">
          <b-list-group>
            <b-list-group-item v-for="(hotel, index) in selectedTour.hotels" :key="index">
              <b-form-radio-group
                  v-model="newTour.hotel.id"
                  name="radio-options">
                <b-form-radio :value="hotel.id">{{hotel.name}}</b-form-radio>
              </b-form-radio-group>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="newTour.hotel.id" class="w-100 mt-1">
          <b-row>
            <b-col cols="6">
              <div class="w-100 mt-1">
                <validation-provider #default="validationContext" name="Hotel Сумма брутто" rules="required|numeric" class="w-100 mt-1">
                  <label for="actual_price">
                    <span class="d-flex">
                      <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                    </span>
                  </label>
                  <b-input v-model="newTour.hotel.actual_price"></b-input>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="w-100 mt-1">
                <validation-provider #default="validationContext" name="Hotel Сумма нетто" rules="required|numeric" class="w-100 mt-1">
                  <label for="market_price">
                    <span class="d-flex">
                      <box-icon name='wallet-alt' type="solid"></box-icon> <span>{{$t('message.Net_amount')}}</span>
                    </span>
                  </label>
                  <b-input v-model="newTour.hotel.market_price"></b-input>
                  <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                </validation-provider>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr>
      <h2><box-icon name='visa' type='logo' ></box-icon></h2>
      <b-row>
        <b-col cols="8">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" :name="$t('message.Title')" rules="required" class="w-100 mt-1">
              <label for="title">{{$t('message.Title')}}</label>
              <b-input v-model="newTour.visa.title"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="4">
          <validation-provider #default="validationContext" :name="$t('message.Countries')" rules="required" class="w-100 mt-1">
            <label class="mt-2">{{$t('message.Countries')}}</label>
            <b-form-select
                v-model="newTour.visa.country_id"
                :options="countries"
                text-field="name_uz"
                value-field="id"
                class="w-100"
                @input="countrySelected"
            />
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" name="Visa Сумма брутто" rules="required|numeric" class="w-100 mt-1">
              <label for="actual_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                </span>
              </label>
              <b-input v-model="newTour.visa.actual_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="w-100 mt-1">
            <validation-provider #default="validationContext" name="Visa Сумма нетто" rules="required|numeric" class="w-100 mt-1">
              <label for="market_price">
                <span class="d-flex">
                  <box-icon name='wallet-alt' type="solid"></box-icon><span>{{$t('message.Net_amount')}}</span>
                </span>
              </label>
              <b-input v-model="newTour.visa.market_price"></b-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <hr>
      <h4>Insurance</h4>
      <div class="w-100">
        <b-row>
          <b-col cols="6">
            <validation-provider #default="validationContext" name="Start Date" rules="required" class="w-100 mt-1">
              <label for="start_date">{{$t('message.Start_date')}}</label>
              <input type="date" class="form-control" v-model="newTour.insurance.start_date">
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider #default="validationContext" name="End Date" rules="required" class="w-100 mt-1">
              <label for="end_date">{{$t('message.End_date')}}</label>
              <input type="date" class="form-control" v-model="newTour.insurance.end_date">
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <div class="w-100 mt-1">
              <validation-provider #default="validationContext" name="Сумма брутто" rules="required|numeric" class="w-100 mt-1">
                <label for="actual_price">
                  <span class="d-flex">
                    <box-icon name='wallet-alt'></box-icon> <span>{{$t('message.Gross_amount')}}</span>
                  </span>
                </label>
                <b-input v-model="newTour.insurance.actual_price"></b-input>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="w-100 mt-1">
              <validation-provider #default="validationContext" name="Сумма нетто" rules="required|numeric" class="w-100 mt-1">
                <label for="market_price">
                  <span class="d-flex">
                    <box-icon name='wallet-alt' type="solid"></box-icon> <span>{{$t('message.Net_amount')}}</span>
                  </span>
                </label>
                <b-input v-model="newTour.insurance.market_price"></b-input>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <div class="w-100 mt-2">
          <validation-provider #default="validationContext" :name="$t('message.Countries')" rules="required" class="w-100 mt-1">
            <label class="mt-2">{{$t('message.Countries')}}</label>
            <b-form-select
                v-model="newTour.insurance.to_country_id"
                :options="countries"
                text-field="name_uz"
                value-field="id"
                class="w-100"
            />
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </validation-provider>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-end mt-3">
        <div>
          <b-button variant="primary" @click="saveSubmit" :disabled="disabled">{{$t('message.Add')}}</b-button>
          <b-button variant="warning" @click="$emit('closeAddExistingModal')" >{{$t('message.Cancel')}}</b-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { addTours, getTours } from '@/api/tours'
import moment from 'moment'
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';
import { required } from '@validations'
import { messages } from 'vee-validate/dist/locale/ru.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

export default {
  name: 'AddExistingTour',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      tours: [],
      newTour: {
        tour: {
          id: null,
          avia_ticket_id: null,
          client_id: null,
          actual_price: null,
          market_price: null,
        },
        hotel: {
          id: null,
          name: '',
          actual_price: null,
          market_price: null,
        },
        avia_sales: {
          actual_price: null,
          market_price: null,
        },
        visa: {
          title: '',
          actual_price: null,
          market_price: null,
          country_id: null,
        },
        insurance: {
          start_date:null,
          end_date: null,
          actual_price: null,
          market_price: null,
          to_country_id: null,
        },
        
      },
      countries: [],
      selectedTour: null,
      disabled: false,
    }
  },
  mounted() {
    this.getActiveTours();
  },
  computed: {
    countries() {
      return this.$store.state['settings/countries']
    }
  },
  methods: {
    getActiveTours() {
      getTours({active:true}).then(res => {
        // this.tours = res.data.data;
        this.tours = res.data.data.map(item => Object.assign({},
            {id: item.id,
              name: `${item.name} - [ ${moment(item.start_date).format('D-MM-Y')} - ${moment(item.end_date).format('D-MM-Y')}] `,
            hotels: item.hotels,avia_ticket: item.avia_ticket}))
      })
    },
    tourSelected() {
      this.selectedTour = this.tours.filter(item => item.id === this.newTour.tour.id)[0];
      this.newTour.tour.avia_ticket_id = this.selectedTour.avia_ticket_id;
    },
    saveSubmit() {
      const isValid = this.$refs.observer.validate();
      if(isValid) {

        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
        this.newTour.tour.client_id = this.$route.params.id;
        addTours(this.newTour).then(() => {
          this.$emit('closeAddExistingModal');
        })
      }
    },
    countrySelected() {
      this.newTour.insurance.to_country_id = this.newTour.visa.country_id;
    }
  }
}
</script>

<style scoped>

</style>
