<template>
  <div>
    <div class="w-100 d-flex justify-content-start" v-if="checkRoleUser">
      <b-button variant="primary" class="mb-1" @click="handleAdd">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table :fields="fields" :items="tickets" responsive :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex" v-if="checkRoleUser">
            <div>
              <b-button
                variant="outline-success"
                size="sm"
                @click="handleShow(data.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal hide-footer v-model="formModalActive" size="xl" no-close-on-backdrop>
      <FormTickets :id="id" @closeModal="closeModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import TicketsForm from '@/views/directories/avia-tickets/components/TicketsForm'
  import {
    deleteAviaTicketSale,
    getTickets
  } from '@/api/avia-tickets'
  import FormTickets from "@/views/directories/clients/components/avia-tickets/Form";
  import {mapGetters} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'Tickets',
    components: {
      FormTickets,
      TicketsForm
    },
    data() {
      return {
        id: null,
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'fly_datetime',
            label: this.$t('message.Fly_datetime')
          },
          {
            key: "arrive_datetime",
            label: this.$t('message.Arrive_datetime')
          },
          {
            key: 'market_price',
            label: this.$t('message.Market_price')
          },
          {
            key: 'payment_type.name_ru',
            label: this.$t('data.payment_type')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        tickets: [],
        formModalActive: false,
        busy: true,
      }
    },
    created() {
      this.fetchTickets()
    },
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if(this.activeUser !== null) {
          return this.activeUser.roles.some(item => ['SuperAdmin' , 'aviaseller'].includes(item.name) )
        }
        return false
      }
    },
    methods: {
      fetchTickets() {
        getTickets({
          client_id: this.$route.params.id
        }).then(response => {
          this.tickets = response.data.data;
          this.tickets.forEach( item => item.market_price = parseInt(item.market_price));
          this.busy = false;
          // this.tours.forEach(item => {
          //   item.fly_datetime = moment(item.fly_datetime).format('YYYY-MM-DD')
          //   item.arrive_datetime = moment(item.arrive_datetime).format('YYYY-MM-DD')
          // })
        })
      },
      handleAdd() {
        this.id = null;
        this.$router.push('/avia-tickets-sales/ticket-add/' + this.$route.params.id)
      },
      handleEdit(id) {
        this.$router.push({name: 'ticket-update', params: {ticketId: id}})
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchTickets()
      },
      handleShow(id) {
        this.$router.push({name: 'ticket-details', params: {ticketDetailsId: id}})
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteAviaTicketSale({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchTickets()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
    }
  }
</script>

<style scoped>

</style>